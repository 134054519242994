import React from 'react';
import { useMsal } from "@azure/msal-react";

function SignOutButton() {
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect().catch(e => {
            console.error(e);
        });
    };

    return <button onClick={handleLogout}>Sign Out</button>;
}

export default SignOutButton;
