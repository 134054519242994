import React from 'react';
import { useMsal } from "@azure/msal-react";
import SignInImage from './ms_signin_dark.png';

function SignInButton() {
    const { instance } = useMsal();

    const handleLogin = () => {
        const loginHint = process.env.NODE_ENV === 'development' ? "karen@knowbots.org" : undefined;
        instance.loginRedirect({
            loginHint: loginHint,
        }).catch(e => {
            console.error(e);
        });
    };

    return <button className="msbutton" onClick={handleLogin}><img src={SignInImage} alt="Sign in to File Baby with Microsoft" /></button>;
}

export default SignInButton;
