// saveToBlob.js
import { BlobServiceClient } from '@azure/storage-blob';

const uploadTextToBlob = async (fileName, textContent) => {
    const blobServiceUrl = process.env.REACT_APP_BLOB_SAS_URL;
    const sasToken = process.env.REACT_APP_SAS_TOKEN;
    const containerName = 'memories';

    if (!blobServiceUrl || !sasToken) {
        throw new Error('Azure Blob Storage credentials are missing.');
    }

    const fullUrl = `${blobServiceUrl}?${sasToken}`;
    const blobServiceClient = new BlobServiceClient(fullUrl);
    const containerClient = blobServiceClient.getContainerClient(containerName);

    // Ensure the container exists
    await containerClient.createIfNotExists();

    const blockBlobClient = containerClient.getBlockBlobClient(fileName);
    await blockBlobClient.upload(textContent, textContent.length);

    return blockBlobClient.url;
};

export default uploadTextToBlob;
