import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { msalInstance } from "./authConfig";
import hero from './justin.png';
import './App.css'; // Import main styles
import './Chatbot.css'; // Import chat-specific styles
import Chatbot from './Chatbot';
import SignInButton from './SignInButton';
import SignOutButton from './SignOutButton';

function AppContent() {
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    const isDevelopment = process.env.NODE_ENV === 'development';
    const userName = isDevelopment ? "karen@knowbots.org" : accounts?.[0]?.username;

    useEffect(() => {
        console.log("Accounts:", accounts);
        console.log("Is Authenticated:", isAuthenticated);
    }, [accounts, isAuthenticated]);

    useEffect(() => {
        msalInstance.handleRedirectPromise().then((response) => {
            if (response) {
                console.log("Login Response:", response);
            }
        }).catch(e => {
            console.error(e);
        });
    }, []);

    return (
        <div className="app-content">
            <header className="App-header">
                <img src={hero} className="hero-img" alt="Justin" />
                {!isAuthenticated && !isDevelopment ? (
                    <SignInButton />
                ) : (
                    <div className="auth-info">
                        <p>Signed in as {userName}</p>
                        <SignOutButton />
                    </div>
                )}
                {isAuthenticated || isDevelopment ? (
                    <div className="chat-container">
                        <div className="chat-popup">
                            <div className="response-container">
                                <Chatbot setFilterCriteria={(criteria) => console.log(criteria)} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>User is not authenticated</p>
                )}
            </header>
        </div>
    );
}

function App() {
    return (
        <MsalProvider instance={msalInstance}>
            <Router>
                <AppContent />
            </Router>
        </MsalProvider>
    );
}

export default App;
